























































import { DateTime } from 'luxon';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// Format that is used by date input component:
const DATE_INPUT_FORMAT = 'yyyy-MM-dd';

@Component
export default class DateRangeSliderComponent extends Vue {
  @Prop({ default: () => DateTime.now().minus({ years: 1 }) })
  private minDate!: DateTime;

  @Prop({ default: () => DateTime.now() })
  private maxDate!: DateTime;

  @Prop({ required: true })
  private onRangeChanged!: (fromDate: DateTime, toDate: DateTime) => void;

  // Min and max date as numbers for slider:
  private minValue: number = this.toNumber(this.minDate);
  private maxValue: number = this.toNumber(this.maxDate);

  // Min and max date as string for datepicker:
  private minInputValue: string = this.minDate.toFormat(DATE_INPUT_FORMAT);
  private maxInputValue: string = this.maxDate.toFormat(DATE_INPUT_FORMAT);

  private fromDate: DateTime = this.minDate;
  private toDate: DateTime = this.maxDate;

  private fromToValues: number[] = [this.toNumber(this.fromDate), this.toNumber(this.toDate)];

  private stepSize: number = 24 * 60 * 60;

  private toNumber(date: DateTime): number {
    return date.toSeconds();
  }

  private fromNumber(val: number): DateTime {
    return DateTime.fromSeconds(val);
  }

  private onRangeValuesChanged() {
    this.fromDate = this.fromNumber(this.fromToValues[0]);
    this.toDate = this.fromNumber(this.fromToValues[1]);
    this.onRangeChanged(this.fromDate, this.toDate);
  }

  @Watch('minInputValue', { immediate: true })
  private onMinInputValueChanged() {
    const minDate = DateTime.fromFormat(this.minInputValue, DATE_INPUT_FORMAT);
    const minValue = this.toNumber(minDate);
    // Only valid if minDate is smaller than maxDate:
    if (minValue <= this.maxValue) {
      this.minValue = minValue;
      // If slider-value is curently lower than new minimum: 
      if (this.fromToValues[0] < minValue) {
        this.fromToValues = [minValue, this.fromToValues[1]];
        this.fromDate = minDate;
      }
    } else {
      // Rollback:
      this.minInputValue = this.minDate.toFormat(DATE_INPUT_FORMAT);
      Vue.notifyWarningSimplified('GENERAL.NOTIFICATIONS.MIN_DATE_GREATER_MAX_DATE');
    }
  }

  @Watch('maxInputValue', { immediate: true })
  private onMaxInputValueChanged() {
    const maxDate = DateTime.fromFormat(this.maxInputValue, DATE_INPUT_FORMAT).endOf('day');
    const maxValue = this.toNumber(maxDate);
    // Only valid if minDate is smaller than maxDate:
    if (this.minValue <= maxValue) {
      this.maxValue = maxValue;
      // If slider-value is curently higher than new minimum: 
      if (this.fromToValues[1] > maxValue) {
        this.fromToValues = [this.fromToValues[0], maxValue];
        this.toDate = maxDate;
      }
    } else {
      // Rollback:
      this.maxInputValue = this.maxDate.toFormat(DATE_INPUT_FORMAT);
      Vue.notifyWarningSimplified('GENERAL.NOTIFICATIONS.MAX_DATE_SMALLER_MIN_DATE');
    }
  }
}
